import { render, staticRenderFns } from "./KeyParts.vue?vue&type=template&id=37b8e3de&scoped=true&"
import script from "./KeyParts.vue?vue&type=script&lang=js&"
export * from "./KeyParts.vue?vue&type=script&lang=js&"
import style0 from "./KeyParts.vue?vue&type=style&index=0&id=37b8e3de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b8e3de",
  null
  
)

export default component.exports